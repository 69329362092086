.footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  width: 100vw;
  align-items: center;

  background-color: #f86a6a;
  height: 10vh;
  text-align: center;
  //   line-height: 1rem;
  h1,
  p {
    color: #243447;
  }
}
