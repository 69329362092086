.nav_mobile {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 1rem;
      &:first-child {
        margin-top: 2rem;
      }
    }
  }
}

.nav_hamburger {
  text-align: center;
  @media screen and (min-width: 650px) {
    display: none;
    color: red;
  }
}

.nav_mobile__hide {
  display: none;
  @media screen and (min-width: 650px) {
    display: flex;
    justify-content: space-around;
  }
}
