.form_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
}

.login_h1 {
  margin-bottom: 30px;
}

.form_tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  color: #3f51b5;
}

input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #3f51b5;
  }
}

.error {
  color: #f44336;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #3f51b5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #283593;
  }

  &:active {
    background-color: #1a237e;
  }
}
