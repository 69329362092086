* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //   outline: 1px dashed olivedrab;
  font-family: sans-serif;
}

body {
  color: white;
  background-color: #243447;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: wheat;
}

main {
  margin: 0 auto;
}
h1 {
  font-size: 2rem;
  color: #f86a6a;
}

h2 {
  color: #4ecdc4;
}
h3 {
  color: #88dff7;
}
p {
  color: #d8dde1;
}

.container {
  padding: 0.8rem;
  max-width: 1400px;
  margin: 0 auto;
}

header {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: inherit;
  }
}
