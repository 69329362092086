.hero_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
  .hero_img {
    width: 100%;
    max-width: 500px;
    img {
      width: 100%;
      border-radius: 2rem;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .hero_text {
    width: 90%;
  }
}
