.post_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    transition: filter 0.5s ease-in-out;
  }
  &:hover img {
    filter: grayscale(100%);
  }

  .post_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;

    h1 {
      margin: 0;
    }

    p {
      margin: 10px 0;
    }
  }

  time {
    font-size: 14px;
    color: #777;
    margin-top: 10px;
  }
}

@media (min-width: 651px) {
  .post_container {
    flex-direction: row;

    img {
      width: 100%;
      max-width: 500px; 
    }

    .post_title {
      width: 50%; 
      align-items: flex-start;
      text-align: left;
      margin-top: 0;
      margin-left: 20px;
    }
  }
}
